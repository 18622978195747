import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.scss";
import Button from "@mui/material/Button";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import FeedbackIcon from "@mui/icons-material/Feedback";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Routes, Route, Link as ReactRouterLink } from "react-router-dom";
import Home from "./pages/home";
import CurrentGame from "./pages/current-game";
import { Link as MuiLink } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Game } from "./types/game";
import { createTheme, colors, ThemeProvider } from "@mui/material";
import { useLocalStorage } from "usehooks-ts";
import Results from "./pages/results";
import { FeedbackFish } from "@feedback-fish/react";
import About from "./pages/about";
import Settings from "./pages/settings";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  MessageType,
} from "./components/confirmation-dialog";
import { set } from "date-fns";
import { useSettingsStore } from "./store";
import { Currency, Currencies } from "./types/currency";

const pokerTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: "#242270",
          color: "white",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {},
        paper: {
          background: "#242270",
          color: "white",
          width: "200px",
          fontSize: "1.2rem",
        },
      },
    },
  },

  palette: {
    mode: "dark",
    // primary: {
    //   //main: colors.orange[500],
    // },
  },
});
function App() {
  // when app loads, look for data in localstorage. if not create a dummy structure

  // const dummyData: Game[] = [
  //   {
  //     id: 4343444,
  //     location: "Nishanth's place",
  //     date: new Date("12/1/2022"),
  //     buyInAmnt: 5,
  //     totalGameBuyInAmnt: 55,
  //     players: [
  //       {
  //         id: 2,
  //         name: "Anup",
  //         totalBuyIn: 3,
  //         finalInHandAmnt: 8.45,
  //         finalCashPayout: -6.55,
  //       },
  //       {
  //         id: 3,
  //         name: "Nishanth",
  //         totalBuyIn: 4,
  //         finalInHandAmnt: 3,
  //         finalCashPayout: -17,
  //       },
  //       {
  //         id: 4,
  //         name: "Tom",
  //         totalBuyIn: 2,
  //         finalInHandAmnt: 15,
  //         finalCashPayout: 5,
  //       },
  //     ],
  //   },
  //   {
  //     id: 4343444,
  //     location: "Nishanth's place",
  //     date: new Date("12/1/2022"),
  //     buyInAmnt: 5,
  //     totalGameBuyInAmnt: 55,
  //     players: [
  //       {
  //         id: 1,
  //         name: "Anup",
  //         totalBuyIn: 3,
  //         finalInHandAmnt: 8.45,
  //         finalCashPayout: -6.55,
  //       },
  //       {
  //         id: 2,
  //         name: "Nishanth",
  //         totalBuyIn: 4,
  //         finalInHandAmnt: 3,
  //         finalCashPayout: -17,
  //       },
  //       {
  //         id: 3,
  //         name: "Tom",
  //         totalBuyIn: 2,
  //         finalInHandAmnt: 15,
  //         finalCashPayout: 5,
  //       },
  //     ],
  //   },
  //   {
  //     id: 4343444,
  //     location: "Nishanth's place",
  //     date: new Date("12/1/2022"),
  //     buyInAmnt: 5,
  //     totalGameBuyInAmnt: 55,
  //     players: [
  //       {
  //         id: 1,
  //         name: "Anup",
  //         totalBuyIn: 3,
  //         finalInHandAmnt: 8.45,
  //         finalCashPayout: -6.55,
  //       },
  //       {
  //         id: 2,
  //         name: "Nishanth",
  //         totalBuyIn: 4,
  //         finalInHandAmnt: 3,
  //         finalCashPayout: -17,
  //       },
  //       {
  //         id: 3,
  //         name: "Tom",
  //         totalBuyIn: 2,
  //         finalInHandAmnt: 15,
  //         finalCashPayout: 5,
  //       },
  //     ],
  //   },
  // ];

  //const [gamesList, setgamesList] = useLocalStorage("POKER_CALC", dummyData);
  let data = localStorage.getItem("POKER_CALC");
  const settings = useSettingsStore((state) => state);
  // if (!data) {
  //   localStorage.setItem("POKER_CALC", JSON.stringify(dummyData));
  // }
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showDemoVideoDialogOpen, setShowDemoVideoDialogOpen] = useState(false);

  const handleClose = () => {
    setShowDemoVideoDialogOpen(false);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const currencySettingsConfirmationData: ConfirmationDialogProps = {
    open: false,
    title: `Watch a demo video`,
    message: `<iframe  src="https://www.youtube.com/embed/Sxto1gjTpLI?si=47MhRMk0ejKJqJf9" title="Poker calculator demo video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
    confirmText: "Go to app",
    //cancelText: `Go to app`,
    messageType: MessageType.INFO,
    embedType: "video",
    onConfirm: () => {
      setShowDemoVideoDialogOpen(false);
      //navigate to settings page
      //navigate("/settings");
    },
    onCancel: () => {
      setShowDemoVideoDialogOpen(false);
    },
  };

  useEffect(() => {
    if (settings.isFirstTimeUser) {
      setShowDemoVideoDialogOpen(true);
      console.log(showDemoVideoDialogOpen);
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((userLocation) => {
          if (userLocation.country_code === "US") {
            setUserCurrency(userLocation.country_code);
            settings.updateIsFirstTimeUser(false);
          }
        })
        .catch((error) => {
          console.error("Error loading location infro from IP API");
          // Incase of error, set USD as default
          setUserCurrency("US");
        });

      // Show an MUI modal popup with embedded YouTube video
      setShowDemoVideoDialogOpen(true);
      settings.updateIsFirstTimeUser(false);
    }
  }, []);

  const setUserCurrency = (countryCode: string) => {
    fetch("/assets/currencies.json")
      .then((response) => response.json())
      .then((currenciesList) => {
        // iterate through the currencies list and find the user's country currency
        for (let currencyCode in currenciesList) {
          if (currenciesList[currencyCode].country_code == countryCode) {
            // set the user's country currency as the selected currency
            settings.updateCurrency({
              ...currenciesList[currencyCode],
              code: currencyCode,
            });
          }
        }
      })
      .catch((error) => {
        //incase of error, set USD as default. This should not happen.
        settings.updateCurrency({
          symbol: "$",
          name: "US Dollar",
          country_code: "US",
          code: "USD",
        });
        console.error("Error loading currencies:", error);
      });
  };

  return (
    <ThemeProvider theme={pokerTheme}>
      <>
        <div className="app-container">
          <div>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
              <List onClick={() => setDrawerOpen(false)}>
                <ListItem component={ReactRouterLink} to="/">
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
                <ListItem component={ReactRouterLink} to="/settings">
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItem>
                <ListItem component={ReactRouterLink} to="/about">
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary="About" />
                </ListItem>
                <ListItem
                  component="a"
                  target="_blank"
                  href="https://youtu.be/Sxto1gjTpLI"
                >
                  <ListItemIcon>
                    <YouTubeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Demo Video" />
                </ListItem>
                <ListItem
                  component="a"
                  target="_blank"
                  href="https://xsszf2w4igp.typeform.com/to/SWwOVUxO"
                >
                  <ListItemIcon>
                    <FeedbackIcon />
                  </ListItemIcon>
                  <ListItemText primary="Feedback" />
                </ListItem>
              </List>
            </Drawer>
            <header>
              <div className="app-header">
                <div>
                  <MuiLink component={ReactRouterLink} to="/">
                    <img
                      src="/images/logo.png"
                      className="app-logo"
                      alt="Poker Calculator"
                    ></img>
                  </MuiLink>
                </div>
                <div>
                  <h1>Poker Calculator</h1>
                </div>
                <div>
                  <IconButton onClick={toggleDrawer}>
                    <MenuIcon />
                  </IconButton>
                </div>
              </div>
              <div className="header-back-button">
                {pathname != "/" && (
                  <Button onClick={() => navigate(-1)}>Back</Button>
                )}
              </div>
            </header>

            <main className="main-content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/current-game/:gameId?"
                  element={<CurrentGame />}
                />
                <Route path="/results/:gameId" element={<Results />} />
                <Route path="/about" element={<About />} />
                <Route path="/settings" element={<Settings />} />
              </Routes>
            </main>
            <footer>
              <div className="align-center">
                {/* <FeedbackFish projectId="05ec1bba16d00d">
                <Button variant="text">Feedback</Button>
              </FeedbackFish> */}

                <Button
                  variant="text"
                  href="https://surveys.hotjar.com/85d9f725-e05d-4143-98c7-c550d21c9e07"
                  target="_blank"
                >
                  Feedback
                </Button>
                <Button variant="text" component={ReactRouterLink} to="/about">
                  About
                </Button>
              </div>
            </footer>
          </div>
        </div>
        <ConfirmationDialog
          {...currencySettingsConfirmationData}
          open={showDemoVideoDialogOpen}
        ></ConfirmationDialog>
      </>
    </ThemeProvider>
  );
}

export default App;
