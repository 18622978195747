import { create } from "zustand";
import { Currency } from "./types/currency";
import { persist, createJSONStorage } from "zustand/middleware";

type SettingsStore = {
  selectedCurrency: Currency;
  currencyOptionShownToUser: boolean;
  isFirstTimeUser: boolean;
  updateIsFirstTimeUser: (isFirstTimeUser: boolean) => void;
  updateCurrency: (currency: Currency) => void;
  updateCurrencyOptionShownToUser: (currencyOptionShownToUser: boolean) => void;
  //handleCurrencyChange: (e: SelectChangeEvent) => void;
};

export const useSettingsStore = create(
  persist<SettingsStore>(
    (set) => ({
      selectedCurrency: {
        code: "USD",
        symbol: "$",
        name: "US Dollar",
        country_code: "US",
      },
      currencyOptionShownToUser: false,
      isFirstTimeUser: true,
      updateIsFirstTimeUser: (isFirstTimeUser: boolean) =>
        set(() => ({ isFirstTimeUser: isFirstTimeUser })),
      updateCurrency: (currency: Currency) =>
        set(() => ({ selectedCurrency: currency })),
      updateCurrencyOptionShownToUser: (currencyOptionShownToUser: boolean) =>
        set(() => ({ currencyOptionShownToUser: currencyOptionShownToUser })),
    }),

    {
      name: "POKER_CALC_SETTINGS",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
