import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import "./confirmation-dialog.scss";

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  messageType?: MessageType; //error, warning, info, success
  embedType?: string;
}

export enum MessageType {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}
export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  message,
  confirmText = "Confirm",
  cancelText,
  onConfirm,
  onCancel,
  messageType,
  embedType,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      className={`confirmation-dialog ${messageType} ${embedType}`}
    >
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      <DialogContent className="multiline">
        {<div dangerouslySetInnerHTML={{ __html: message }}></div>}
      </DialogContent>
      <DialogActions className="dialog-buttons">
        {cancelText && (
          <Button
            onClick={onCancel}
            disableRipple
            autoFocus
            variant="outlined"
            className="cancel-btn"
          >
            {<div dangerouslySetInnerHTML={{ __html: cancelText }}></div>}
          </Button>
        )}
        <Button
          onClick={onConfirm}
          variant="contained"
          color={messageType}
          className="primary-btn"
        >
          {<div dangerouslySetInnerHTML={{ __html: confirmText }}></div>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
