import { boolean, z } from "zod";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import {
  Button,
  TextField,
  Container,
  Box,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Game, GameSchema } from "../types/game";
import { Player, PlayerSchema } from "../types/player";
import "./current-game.scss";
import { useState, useEffect } from "react";
import { AddEditPlayer } from "../components/add-edit-player";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useParams, useNavigate } from "react-router-dom";
import { useReadLocalStorage, useLocalStorage } from "usehooks-ts";
import moment from "moment";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import {
  ConfirmationDialogProps,
  ConfirmationDialog,
  MessageType,
} from "../components/confirmation-dialog";
import { getDateSectionConfigFromFormatToken } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { set } from "date-fns";
import { ca } from "date-fns/locale";
import { useSettingsStore } from "../store";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// type FormProps = {
//   onSubmit: SubmitHandler<Game>;
// };
//TODO: add zodresolver
//Todo: useFieldArray hook for dynamic forms https://react-hook-form.com/docs/usefieldarray
const CurrentGame = () => {
  const currency = useSettingsStore((state) => state.selectedCurrency);
  console.log(currency);
  const navigate = useNavigate();
  var savedGame: Game | null | undefined = null; //TODO: pull saved game from state if we are editing game.
  const { gameId } = useParams();
  const savedGames = useReadLocalStorage<Game[]>("POKER_CALC");

  if (gameId) {
    savedGame = savedGames
      ? savedGames.find((g) => g.id === Number(gameId))
      : null;
    //console.log(savedGame);
  }

  //if game is not found, create a new game
  const [currentGame, setCurrentGame] = useState<Game>(
    savedGame ?? {
      location: "",
      totalGameBuyInAmnt: 0,
      buyInAmnt: 5,
      id: Math.floor(Math.random() * 1000000000),
      date: new Date(moment.utc(new Date()).format("YYYY-MM-DD")),
      players: [],
    }
  );

  // state variable totalUnSettledAmount
  const [totalUnSettledAmount, setTotalUnSettledAmount] = useState(0);

  //Confirmation Dialog content
  const [confirmationDialogContent, setConfirmationDialogContent] =
    useState<ConfirmationDialogProps>({
      open: false,
      title: "",
      message: "",
      confirmText: "OK",
      cancelText: "",
      onConfirm: () => {},
      onCancel: () => {},
    });

  // add/update currentGame to list of games in  "POKER_CALC" localstorage using useLocalStorage hook

  useEffect(() => {
    //save a new game only if location or players values are changed.
    // This is to avoid saving games after starting a new game and going back without adding any data
    if (
      currentGame &&
      (currentGame.location !== "" ||
        (currentGame.players && currentGame.players?.length > 0))
    ) {
      //calculate totalGameBuyInAmnt in currentGame and update the property
      // const { totalBuyInAllPlayers, totalInHandAmntAllPlayers } =
      //   calculateUnSettledAmount();

      currentGame.totalGameBuyInAmnt =
        calculateUnSettledAmount().totalBuyInAllPlayers;

      var savedGames = JSON.parse(localStorage.getItem("POKER_CALC") || "[]");
      if (savedGames) {
        const index = savedGames.findIndex(
          (g: Game) => g.id === currentGame.id
        );
        if (index === -1) {
          //add new game
          savedGames.push(currentGame);
        } else {
          //update game
          savedGames[index] = currentGame;
        }
      } else {
        //add new game
        savedGames = [currentGame];
      }
      localStorage.setItem("POKER_CALC", JSON.stringify(savedGames));
      calculateUnSettledAmount();
    }
  }, [currentGame]);

  const [showAddEditUser, setShowAddEditUser] = useState(0); // 0 = false, 1 = add, 2 = edit
  const [playerToEdit, setPlayerToEdit] = useState<Player>({
    id: Date.now(), //set unique id
    name: "",
    totalBuyIn: 5, //in dollars
    finalInHandAmnt: 0,
    finalCashPayout: 0,
  }); // 0 = false, 1 = add, 2 = edit

  //Callback function after add or edit player
  const addedOrEditedPlayer = (
    player: (Player & { isDeleted?: Boolean }) | null
  ) => {
    console.log(player);

    //if player.isDeleted is true, delete the player from currentGame.players
    if (player && player.isDeleted) {
      if (!currentGame.players) return;
      const newPlayerArray: Array<Player> = currentGame.players.filter(
        (p) => p.id !== player.id
      );
      setCurrentGame({ ...currentGame, players: newPlayerArray });
    } else if (showAddEditUser === 1) {
      // Add player

      if (player) {
        const newPlayerArray: Array<Player> = currentGame.players
          ? currentGame.players.concat(player)
          : [player];

        setCurrentGame({ ...currentGame, players: newPlayerArray });
      }
    } else if (showAddEditUser === 2) {
      // update the player
      if (currentGame.players && player) {
        const newPlayerArray: Array<Player> = currentGame.players;
        const index = newPlayerArray.findIndex((p) => p.id === player.id);
        if (index === -1) return;
        newPlayerArray[index] = player;

        setCurrentGame({ ...currentGame, players: newPlayerArray });
      }
    }
    //reset showAddEditUser after add or edit is complete
    setShowAddEditUser(0);
  };

  const addPlayer = () => {
    setPlayerToEdit({
      id: Date.now(), //set unique id
      name: "",
      totalBuyIn: currentGame.buyInAmnt,
      finalInHandAmnt: 0,
      finalCashPayout: 0,
    });
    setShowAddEditUser(1);
  };

  const editPlayer = (index: number) => {
    if (!currentGame.players) return;
    setPlayerToEdit(currentGame.players[index]);
    setShowAddEditUser(2);
  };

  const addBuyInForPlayer = (index: number, buyIn: number) => {
    if (!currentGame.players) return;
    currentGame.players[index].totalBuyIn += buyIn;
    //update finalPayout too when buyIn is added
    currentGame.players[index].finalCashPayout = Number(
      (
        currentGame.players[index].finalInHandAmnt -
        currentGame.players[index].totalBuyIn
      ).toFixed(2)
    );
    setCurrentGame({ ...currentGame });
  };

  // move showDeleteConfirmation to state
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const handleDeleteConfirmation = (isDelete: Boolean) => {
    setShowDeleteConfirmation(false);
    if (isDelete) {
      //delete the game from gamesFullList and localStorage and navigate to home page
      var savedGames = JSON.parse(localStorage.getItem("POKER_CALC") || "[]");
      if (savedGames) {
        const index = savedGames.findIndex(
          (g: Game) => g.id === currentGame.id
        );
        if (index != -1) {
          //delete game
          savedGames.splice(index, 1);
          localStorage.setItem("POKER_CALC", JSON.stringify(savedGames));
        }
      }
      //navigate to home page
      navigate("/");
    }
  };

  const calculateUnSettledAmount = () => {
    let totalBuyInAllPlayers: number = 0;
    let totalInHandAmntAllPlayers: number = 0;
    if (currentGame.players) {
      currentGame.players.forEach((player) => {
        totalBuyInAllPlayers += player.totalBuyIn;
        totalInHandAmntAllPlayers += player.finalInHandAmnt;
      });
      setTotalUnSettledAmount(totalBuyInAllPlayers - totalInHandAmntAllPlayers);
    }
    return { totalBuyInAllPlayers, totalInHandAmntAllPlayers };
  };

  const declareResults = () => {
    if (!currentGame.players) return;

    if (currentGame.location === "") {
      alert("Please enter a Location/Name for the game");
      return;
    }
    // loop throught players and add up total buy-in and final in-hand amount and if
    // final in-hand amount is not equal to total buy-in, display an alert message about the difference
    // and ask user to fix the difference before declaring results
    let totalBuyIn = 0;
    let totalInHandAmnt = 0;
    //let totalCashPayout = 0;

    currentGame.players.forEach((player) => {
      totalBuyIn += player.totalBuyIn;
      totalInHandAmnt += player.finalInHandAmnt;
    });

    totalBuyIn = Number(totalBuyIn.toFixed(2));
    totalInHandAmnt = Number(totalInHandAmnt.toFixed(2));

    if (totalBuyIn != totalInHandAmnt) {
      // replace the below alert with ConfirmationDialog component
      //const confirmationMessage =
      setConfirmationDialogContent({
        open: true,
        title: "Discrepancy Detected",
        message: `Final In-Hand amount is not equal to Total Buy-In.

    Total Buy-In: <strong>${currency.symbol}${totalBuyIn} </strong>
    Final In-Hand Amount: <strong>${currency.symbol}${totalInHandAmnt}</strong>
         
Please fix the final In-Hand amounts by clicking the edit icon in players list.`,
        confirmText: "Override and Declare Results",
        cancelText: "OK",
        messageType: MessageType.ERROR,
        onConfirm: () => {
          //override and declare results
          navigate(`/results/${currentGame.id}`);
        },
        onCancel: () => {
          setConfirmationDialogContent({
            ...confirmationDialogContent,
            open: false,
          });
        },
      });
      // alert(
      //   `Final In-Hand amount is not equal to Total Buy-In.
      // Total Buy-In: $${totalBuyIn}
      // Final In-Hand Amount: $${totalInHandAmnt}
      //  Please fix the difference before declaring results.`
      // );
      return;
    } else {
      navigate(`/results/${currentGame.id}`);
    }
  };

  return (
    <Container maxWidth="sm" className="current-game-container">
      <Box sx={{ mt: 4 }}>
        <TextField
          label="Location/Game Name"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true, required: true }}
          error={currentGame.location === ""}
          helperText=""
          size="small"
          value={currentGame.location}
          onChange={(e) =>
            setCurrentGame({ ...currentGame, location: e.target.value })
          }
        />
        <div className="date-buy-in-container">
          <TextField
            label="Date"
            type="date"
            value={moment(currentGame.date).format("YYYY-MM-DD")}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true, required: true }}
            size="small"
            onChange={(e) =>
              setCurrentGame({
                ...currentGame,
                date: moment(e.target.value).toDate(),
              })
            }
          />

          <TextField
            error={currentGame.buyInAmnt <= 0}
            label="Min Buy-In"
            type="Number"
            fullWidth
            margin="normal"
            size="small"
            value={currentGame.buyInAmnt}
            onChange={(e) =>
              setCurrentGame({
                ...currentGame,
                buyInAmnt: Number(e.target.value),
              })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {currency.symbol}
                </InputAdornment>
              ),
            }}
          />
        </div>
        {/* <TextField
            {...register("totalGameBuyInAmnt")}
            label="Total Game Buy-In Amount"
            type="number"
            fullWidth
            margin="normal"
          /> */}
        <div className="players-heading-container">
          <h2>Players</h2>{" "}
          {/* <IconButton
            aria-label="Add Player"
            size="large"
            onClick={() => addPlayer()}
          >
            <PersonAddAlt1Icon fontSize="inherit" />
          </IconButton> */}
          <Button
            className="add-player-btn"
            size="medium"
            onClick={() => addPlayer()}
            startIcon={<PersonAddAlt1Icon />}
          >
            Add Player
          </Button>
        </div>
        <div>
          {/* TODO: add headings <div className="player-heading-section">
            <div className="player-row">
              <div className="player-name-container">
                <div className="player-name">Name </div>
              </div>
            </div>
          </div> */}
          {currentGame.players && currentGame.players.length > 1 && (
            <div className="unsettled-amount">
              <label>Unsettled amount</label> {totalUnSettledAmount.toFixed(2)}
            </div>
          )}

          {currentGame.players && currentGame.players.length > 0 ? (
            currentGame.players.map((player, index) => {
              return (
                <div className="player-section" key={index}>
                  <div
                    className={`player-row ${
                      player.finalInHandAmnt > 0 ? "final-amnt-added" : ""
                    }`}
                  >
                    <div className="player-name-container">
                      <div className="player-name"> {player.name}</div>
                    </div>

                    <div>
                      <IconButton
                        aria-label="Add buy-in"
                        size="medium"
                        className="players-btn"
                        onClick={() =>
                          addBuyInForPlayer(index, currentGame.buyInAmnt)
                        }
                      >
                        <AddCircleOutlineIcon
                          fontSize="inherit"
                          className="players-btn-icon"
                        />
                      </IconButton>
                    </div>
                    <div className="dollar-amnt"> {player.totalBuyIn}</div>
                    <IconButton
                      aria-label="Add buy-in"
                      size="medium"
                      className="players-btn"
                      onClick={() =>
                        addBuyInForPlayer(index, -currentGame.buyInAmnt)
                      }
                    >
                      <RemoveCircleOutlineIcon
                        fontSize="inherit"
                        className="players-btn-icon"
                      />
                    </IconButton>

                    <div className="dollar-amnt"> {player.finalInHandAmnt}</div>
                    <div className="dollar-amnt"> {player.finalCashPayout}</div>

                    <IconButton
                      aria-label="Edit Player"
                      className="edit-player-btn"
                      size="small"
                      onClick={() => editPlayer(index)}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="info-message">
              No players added so far. Use the 'Add Player' button above to
              start the game.
            </div>
          )}
        </div>
        {/* <div>
          <Button
            type="button"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => addPlayer()}
          >
            Add Player
          </Button>
        </div> */}
        <Button
          type="button"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={() => declareResults()}
          disabled={currentGame.players && currentGame.players.length < 2}
        >
          Declare Results
        </Button>
        <div className="delete-player-btn">
          <Button
            variant="text"
            color="error"
            onClick={() => setShowDeleteConfirmation(true)}
          >
            Delete Game
          </Button>
        </div>
      </Box>
      {!!showAddEditUser && (
        <AddEditPlayer
          openDialog={showAddEditUser}
          playerToEdit={playerToEdit}
          addedOrEditedPlayer={addedOrEditedPlayer}
        ></AddEditPlayer>
      )}

      {!!showDeleteConfirmation && (
        <Dialog
          sx={{
            "& .MuiPaper-root": {
              background: "#920a0a",
            },
            "& .MuiBackdrop-root": {
              backgroundColor: "transparent",
            },
          }}
          open={showDeleteConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Game? </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deleting this game is permanent and cannot be undone. Are you sure
              you want to delete this game?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="delete-no-btn"
              onClick={() => handleDeleteConfirmation(false)}
              style={{ color: "white" }}
            >
              No
            </Button>
            <Button
              className="delete-yes-btn"
              style={{ color: "red", backgroundColor: "#fff" }}
              onClick={() => handleDeleteConfirmation(true)}
              autoFocus
            >
              Yes, Delete this game
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <ConfirmationDialog {...confirmationDialogContent}></ConfirmationDialog>
    </Container>
  );
};

export default CurrentGame;
