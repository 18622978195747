import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Game } from "../types/game";
import "./results.scss";
import { Player } from "../types/player";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, Container } from "@mui/material";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import moment from "moment";
import { useSettingsStore } from "../store";

// const Results = () => {
//   const { gameId } = useParams<{ gameId: string }>();
//   const [game, setGame] = useState<Game | null>(null);

//   useEffect(() => {
//     const storedGame = localStorage.getItem("POKER_CALC");
//     if (storedGame) {
//       const parsedGame: Game[] = JSON.parse(storedGame);
//       const filteredGame = parsedGame.find((g) => g.id === Number(gameId));
//       setGame(filteredGame || null);
//     }
//   }, [gameId]);

const Results = () => {
  const currency = useSettingsStore((state) => state.selectedCurrency);
  const { width, height } = useWindowSize();
  const { gameId } = useParams<{ gameId: string }>();
  const [game, setGame] = useState<Game | null>(null);
  const [rankedPlayers, setRankedPlayers] = useState<Array<
    Player & { rank?: number }
  > | null>(null);

  useEffect(() => {
    const storedGame = localStorage.getItem("POKER_CALC");
    if (storedGame) {
      const parsedGame: Game[] = JSON.parse(storedGame);
      const filteredGame = parsedGame.find((g) => g.id === Number(gameId));
      setGame(filteredGame || null);
      assignPlayerRank(filteredGame?.players || []);
    }
  }, [gameId]);

  const assignPlayerRank = (players: Array<Player & { rank?: number }>) => {
    const sortedPlayers = [...players].sort(
      (a, b) => b.finalCashPayout - a.finalCashPayout
    );
    let rank = 1;
    sortedPlayers.forEach((player, index) => {
      if (
        index > 0 &&
        player.finalCashPayout !== sortedPlayers[index - 1].finalCashPayout
      ) {
        rank = index + 1;
      }
      player.rank = rank;
    });

    setRankedPlayers(sortedPlayers);
  };

  return (
    <Container maxWidth="sm" className="results-container">
      <Confetti
        width={width}
        height={height}
        numberOfPieces={300}
        recycle={false}
      />
      <Box sx={{ mt: 4 }}>
        <div>
          {rankedPlayers ? (
            <div>
              <div className="title-area">
                <img
                  src="/images/ribbon.png"
                  className="ribbon"
                  alt="ribbon image"
                ></img>
                <h2>RESULTS</h2>
              </div>
              <div className="game-details">
                <div>{game?.location} </div>
                <div>{moment(game?.date).format("MMM Do YY")} </div>
              </div>
              <div className="player-cards">
                {rankedPlayers &&
                  rankedPlayers
                    .sort((a, b) => b.finalCashPayout - a.finalCashPayout)
                    .map((player, index) => (
                      <div key={player.id} className={`player-content `}>
                        <div className="player-name-container">
                          <div className="player-name">{player.name}</div>
                        </div>
                        <div className="player-cash">
                          <div className="cash-value">
                            {currency.symbol}
                            {player.finalCashPayout}
                          </div>
                        </div>
                        <div className="player-rank">
                          {player.rank && player.rank <= 3 && (
                            <img
                              src={`/images/${player.rank}.png`}
                              alt={`Player rank ${player.rank}`}
                            />
                          )}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          ) : (
            <div>No game found with id {gameId}</div>
          )}
        </div>
      </Box>
    </Container>
  );
};

export default Results;
