import React, { useEffect, useState } from "react";
import {
  NativeSelect,
  Select,
  SelectChangeEvent,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import { Currency, Currencies } from "../types/currency";
import { useSettingsStore } from "../store";
import "./settings.scss";

const Settings = () => {
  const settings = useSettingsStore((state) => state);

  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(() => {
    return settings.selectedCurrency;
  });

  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const [currencies, setCurrencies] = useState<Currencies>({});
  useEffect(() => {
    // Load currencies from the JSON file
    fetch("/assets/currencies.json")
      .then((response) => response.json())
      .then((data) => setCurrencies(data))
      .catch((error) => console.error("Error loading currencies:", error));
  }, []);
  //const currencyOptions = currencies; //["$", "€", "¥", "£"]; // Replace with actual currency symbols
  const languageOptions = ["English", "Spanish", "French", "German"]; // Replace with actual language options

  const handleCurrencyChange = (e: SelectChangeEvent) => {
    let currency = { ...currencies[e.target.value], code: e.target.value };
    //setSelectedCurrency(currencies[currencyCode]);
    //setSelectedCurrency(e.target.value);
    handleSave(currency);
  };

  const handleLanguageChange = (e: SelectChangeEvent) => {
    setSelectedLanguage(e.target.value);
  };

  const handleSave = (currency: Currency) => {
    // const settings = {
    //   currency: currency,
    //   //language: selectedLanguage,
    // };
    //localStorage.setItem("POKER_CALC_SETTINGS", JSON.stringify(settings));
    settings.updateCurrency(currency);
    setSelectedCurrency(currency);
  };

  return (
    <div className="settings-container">
      <Box
        sx={{ textAlign: "center", display: "flex", justifyContent: "center" }}
      >
        <Box sx={{ mt: 4, textAlign: "left" }}>
          <div className="">
            <label htmlFor="currency">Currency:</label>
            <Select
              inputProps={{ style: { maxWidth: 100 } }}
              labelId="currency-select-label"
              id="currency-select"
              value={selectedCurrency.code}
              label="Currency"
              onChange={handleCurrencyChange}
            >
              {Object.entries(currencies).map(([code, { name, symbol }]) => (
                <MenuItem key={code} value={code}>
                  {name} ({symbol})
                </MenuItem>
              ))}
            </Select>
          </div>
          {/* <div>
          <label htmlFor="language">Language:</label>
          <Select
            id="language"
            value={selectedLanguage}
            onChange={handleLanguageChange}
          >
            {languageOptions.map((language) => (
              <MenuItem key={language} value={language}>
                {language}
              </MenuItem>
            ))}
          </Select>
        </div> */}
          {/* <Button onClick={handleSave}>Save</Button> */}
        </Box>
      </Box>
    </div>
  );
};

export default Settings;
