import Button from "@mui/material/Button";
import GameCard from "../components/game-card";
import {
  Routes,
  Route,
  Link as ReactRouterLink,
  useNavigate,
} from "react-router-dom";

import "./home.scss";
import { useEffect, useState } from "react";
import { Game } from "../types/game";
function Home() {
  //move gamesFullList to state
  const [gamesFullList, setGamesFullList] = useState<Game[] | []>(
    JSON.parse(localStorage.getItem("POKER_CALC") || "[]")
  );

  //setGamesFullList(JSON.parse(localStorage.getItem("POKER_CALC") || "[]"));
  //useEffect to update gamesFullList when localStorage changes
  // useEffect(() => {
  //   setGamesFullList(JSON.parse(localStorage.getItem("POKER_CALC") || "[]"));
  // }, [gamesFullList]);

  const gameCardBgColors = [
    "#ce246e",
    "#b239a4",
    "#c63d3d",
    "#1e7b8f",
    "#d8104c",
    "#4357AD",
    "#1e7b8f",
    "#D33E43",
    "#5b367f",
    "#2d815d",
    "#9c1ae7",
  ];
  return (
    <div>
      <div>
        <Button
          variant="contained"
          component={ReactRouterLink}
          to="/current-game"
          className="start-game-btn"
        >
          Start a new game
        </Button>
      </div>
      <h2>History</h2>
      <div>
        {gamesFullList &&
          gamesFullList.length > 0 &&
          `${gamesFullList.length} games `}
      </div>

      {gamesFullList && gamesFullList.length > 0 ? (
        gamesFullList
          .sort(
            (a: Game, b: Game) =>
              new Date(b.date).getTime() - new Date(a.date).getTime()
          )
          .map((item: any, index: number) => (
            <GameCard
              gameId={item.id}
              aria-role="button"
              totalGameBuyInAmnt={item.totalGameBuyInAmnt}
              {...item}
              bgColor={gameCardBgColors[index % gameCardBgColors.length]}
              key={index}
            />
          ))
      ) : (
        <div className="info-message">
          No games played yet. Start a new game to get started.
        </div>
      )}

      <div></div>
    </div>
  );
}

export default Home;
