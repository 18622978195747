import { z } from "zod";
export const PlayerSchema = z.object({
  id: z.number(),
  name: z.string(),
  totalBuyIn: z.coerce.number(),
  finalInHandAmnt: z.coerce.number(),
  finalCashPayout: z.coerce.number(),
});
export type Player = z.infer<typeof PlayerSchema>;

// export type Player = {
//   name: string;
//   totalBuyIn: number;
//   finalInHandAmnt: number;
//   finalCashPayout: number;
// };
