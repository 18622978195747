import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Player } from "../types/player";
import "./game-card.scss";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSettingsStore } from "../store";

const GameCard = ({
  gameId,
  location,
  date,
  totalGameBuyInAmnt,
  players,
  bgColor,
}: {
  gameId: number;
  location: string;
  date: string;
  players: Player[];
  bgColor: string;
  totalGameBuyInAmnt: number;
}) => {
  const iconNumber = Math.floor(Math.random() * 10) + 1;
  //sort players by win amount
  const playersSortedByWin = players.sort(
    (p1, p2) => p2.finalCashPayout - p1.finalCashPayout
  );
  //console.log(playersSortedByWin);
  const navigate = useNavigate();
  const currency = useSettingsStore((state) => state.selectedCurrency);
  return (
    <div
      className="card-container"
      style={{ background: bgColor }}
      onClick={() => {
        //console.log(gameId);
        navigate("/current-game/" + gameId);
      }}
    >
      <div className="text-content">
        <span className="date"> {moment(date).format("MMM Do YY")}</span> -{" "}
        <span>{location}</span>
        <div className="total-buyin">
          Total Buy-In {currency.symbol}
          {totalGameBuyInAmnt}
        </div>
        <div>
          {/* top 3 players*/}
          {playersSortedByWin.slice(0, 2).map((player, key) => (
            <div key={key}>
              {player.name} {player.finalCashPayout}
            </div>
          ))}
        </div>
      </div>

      <img src={`/icons/${iconNumber}.png`} alt="" />
    </div>
  );
};

export default GameCard;
