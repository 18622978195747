import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField, Button, Typography } from "@mui/material";
import { Player, PlayerSchema } from "../types/player";
import "./add-edit-player.scss";
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  MessageType,
} from "./confirmation-dialog";
import { is } from "date-fns/locale";
import { useSettingsStore } from "../store";

export const AddEditPlayer = ({
  openDialog,
  playerToEdit,
  addedOrEditedPlayer,
}: {
  openDialog: number;
  playerToEdit: Player;
  addedOrEditedPlayer: (
    player: (Player & { isDeleted?: Boolean }) | null
  ) => void;
}) => {
  const currency = useSettingsStore((state) => state.selectedCurrency);
  const [open, setOpen] = useState(false);
  const [showPlayerDeleteConfirmation, setPlayerShowDeleteConfirmation] =
    useState(false);
  var isPlayerDeleted = false;

  const playerConfirmationData: ConfirmationDialogProps = {
    open: false,
    title: `Delete player ${playerToEdit.name}?`,
    message: `Are you sure you want to delete <strong> ${playerToEdit.name} </strong>? This action cannot be undone.`,
    confirmText: "Delete",
    cancelText: "Cancel",
    messageType: MessageType.ERROR,
    onConfirm: () => {
      isPlayerDeleted = true;
      setPlayerShowDeleteConfirmation(false);
      //close current dialog
      setOpen(false);
      addedOrEditedPlayer({ ...playerToEdit, isDeleted: true });
    },
    onCancel: () => {
      isPlayerDeleted = false;
      setPlayerShowDeleteConfirmation(false);
    },
  };
  const cancel = () => {
    setOpen(false);
    addedOrEditedPlayer(null);
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm<Player>({
    defaultValues: { ...playerToEdit },
    resolver: zodResolver(PlayerSchema),
  });

  const finalInHandAmntWatchValue = watch("finalInHandAmnt");
  const totalBuyInWatchValue = watch("totalBuyIn");

  const formSubmitted = (data: Player) => {
    //console.log(data);
    if (data.name == null || data.name.trim().length == 0) {
      alert("Player Name cannot be empty");
      return;
    }
    setOpen(false);
    const modifiedPlayerData = { ...data, isDeleted: isPlayerDeleted };
    addedOrEditedPlayer(modifiedPlayerData);
  };

  useEffect(() => {
    reset(playerToEdit);
    setOpen(!!openDialog);
  }, [openDialog]);

  useEffect(() => {
    //console.log(`finalCashPayout before: ${getValues("finalCashPayout")}`);
    setValue(
      "finalCashPayout",
      Number((finalInHandAmntWatchValue - totalBuyInWatchValue).toFixed(2))
    );
    //console.log(`finalCashPayout after: ${getValues("finalCashPayout")}`);
  }, [finalInHandAmntWatchValue, totalBuyInWatchValue]);

  return (
    <div>
      {/* https://stackoverflow.com/questions/75644447/autofocus-not-working-on-open-form-dialog-with-button-component-in-material-ui-v
      had to add  disableRestoreFocus because of above issue */}
      <Dialog
        disableRestoreFocus
        open={open}
        sx={{
          "& .MuiPaper-root": {
            background: "#2b1871", //"#24035f", //"#035f59",
            // backgroundImage:
            //   "linear-gradient(1turn, rgb(232, 42, 122), rgb(54, 3, 84))",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogTitle>{openDialog === 1 ? "Add" : "Edit"} Player</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(formSubmitted)}>
            <div className="add-player-form">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    autoFocus
                  />
                )}
              />
              <Controller
                name="totalBuyIn"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Total Buy-In Amount"
                    variant="outlined"
                    fullWidth
                    type="number"
                    error={!!errors.totalBuyIn}
                    helperText={errors.totalBuyIn?.message}
                  />
                )}
              />
              <Controller
                name="finalInHandAmnt"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    //inputRef={ref}
                    //value={value}
                    label="Final In-Hand Amount"
                    variant="outlined"
                    fullWidth
                    type="number"
                    error={!!errors.finalInHandAmnt}
                    helperText={errors.finalInHandAmnt?.message}
                  />
                )}
              />
              {/* <Controller
                name="finalCashPayout"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Final Cash Payout"
                    variant="outlined"
                    fullWidth
                    type="number"
                    error={!!errors.finalCashPayout}
                    helperText={errors.finalCashPayout?.message}
                  />
                )}
              /> */}
              <div className="final-payout-container">
                <span className="final-payout-label"> Final Payout: </span>
                <span className="final-payout-amnt">
                  {currency.symbol}
                  {(finalInHandAmntWatchValue - totalBuyInWatchValue).toFixed(
                    2
                  )}
                </span>
              </div>
              <Button type="submit" variant="contained" fullWidth>
                Submit
              </Button>
              <Button variant="contained" onClick={cancel}>
                Cancel
              </Button>
              {openDialog != 1 && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setPlayerShowDeleteConfirmation(true)}
                >
                  Delete Player
                </Button>
              )}
            </div>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <ConfirmationDialog
        {...playerConfirmationData}
        open={showPlayerDeleteConfirmation}
      ></ConfirmationDialog>
    </div>
  );
};
