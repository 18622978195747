import React from "react";
import "./about.scss";
import { Container } from "@mui/material";

const About: React.FC = () => {
  return (
    <Container maxWidth="sm">
      <div className="about-content">
        <h1>About</h1>
        <div>
          <p>
            <strong>Poker Calculator</strong> is a simple{" "}
            <a
              href="https://www.youtube.com/watch?app=desktop&v=AwfKUpq5seE"
              target="_blank"
            >
              Progressive web app (PWA)
            </a>{" "}
            designed for settling cash games. Whether you're a casual player or
            a seasoned pro, this app is your go-to solution for accurately
            tracking player buy-ins, calculating final payouts, and declaring
            results with ease.
          </p>

          <h3>Key Features:</h3>
          <ul>
            <li>
              <strong>Buy-In Tracker:</strong> Keep a precise record of each
              player's buy-ins to ensure fair and transparent gameplay.
            </li>
            <li>
              <strong>Real-time Calculations:</strong> Instantly calculate final
              payouts, making post-game settlements a breeze.
            </li>
            <li>
              <strong>Leaderboard:</strong> Effortlessly view a sorted list of
              players based on their win amounts, allowing you to see who
              dominated the table.
            </li>
            <li>
              <strong>User-Friendly Interface:</strong> Intuitive and easy to
              use, making it accessible for players of all levels.
            </li>
          </ul>

          <h3>
            How to Install on iOS and Android as a Progressive Web App (PWA):
          </h3>

          <p>
            <strong>iOS:</strong>
          </p>
          <ol>
            <li>Open Safari and navigate to https://pokercalculator.app</li>
            <li>Tap the share icon at the bottom of the screen.</li>
            <li>Scroll down and tap on "Add to Home Screen."</li>
            <li>
              Customize the app name if desired, then tap "Add" in the top-right
              corner.
            </li>
          </ol>

          <p>
            <strong>Android:</strong>
          </p>
          <ol>
            <li>Open Chrome and go to http://pokercalculator.app</li>
            <li>
              Tap the three dots in the top-right corner to open the menu.
            </li>
            <li>Select "Add to Home screen."</li>
            <li>Customize the app name if desired, then tap "Add."</li>
          </ol>

          <p>
            Now, you can access Poker Calculator directly from your home screen,
            enjoying the convenience of a native app without the need for
            downloads or app store installations.
          </p>

          <p>
            For any questions or assistance, please use the Feedback link in the
            footer.
          </p>

          <p>
            Thank you for choosing Poker Calculator - where poker meets
            simplicity and precision!
          </p>
        </div>
      </div>
    </Container>
  );
};

export default About;
